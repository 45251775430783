<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>模考</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '' }">考试详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="button-wrap">
        <el-button type="primary"
                   size="small"
                   @click="createDialog=true">新建</el-button>
      </div>
    </div>
    <el-card style="margin-top: 20px;padding-top:20px;">
      <el-form class="search-wrap"
               ref="searchForm"
               :inline="true"
               :model="query"
               size="small"
               label-position="left">
        <el-form-item label="学员信息">
          <el-input v-model.trim="query.student_keyword"
                    placeholder="姓名/手机号"
                    @input="getList(query,true)"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="试卷">
          <el-input v-model.trim="query.exam_paper_keyword "
                    placeholder="试卷名"
                    @input="getList(query,true)"
                    clearable></el-input>
        </el-form-item>

        <el-form-item label="阅卷人">
          <el-input v-model.trim="query.corrector_keyword "
                    placeholder="名字/手机号"
                    @input="getList(query,true)"
                    clearable></el-input>
        </el-form-item>

        <el-form-item label="报名班次">
          <el-select v-model="query.product_ids"
                     placeholder="请选择"
                     @change="getList(query,true)"
                     clearable
                     multiple>
            <el-option v-for="item in $store.state.productOptions"
                       :key="item.id"
                       :label="item.name"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发放人">
          <el-select v-model="query.creator"
                     placeholder="请选择"
                     @change="getList(query,true)"
                     clearable
                     multiple>
            <el-option v-for="item in $store.state.staffOptions"
                       :key="item.id"
                       :label="item.alias"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="考试状态">
          <el-select v-model="query.exam_status"
                     placeholder="请选择"
                     @change="getList(query,true)"
                     clearable
                     multiple>
            <el-option v-for="(item,index) in filters.exam_status"
                       :key="index"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="批改状态">
          <el-select v-model="query.correct_status"
                     placeholder="请选择"
                     @change="getList(query,true)"
                     clearable
                     multiple>
            <el-option v-for="(item,index) in filters.correct_status"
                       :key="index"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="查看状态">
          <el-select v-model="query.read_status"
                     placeholder="请选择"
                     @change="getList(query,true)"
                     clearable
                     multiple>
            <el-option v-for="(item,index) in filters.read_status"
                       :key="index"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="学员类型">
          <el-select v-model="query.is_has_student_id"
                     placeholder="请选择"
                     @change="getList(query,true)"
                     clearable>
            <el-option v-for="(item,index) in filters.student_status"
                       :key="index"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <!-- student_status -->
      </el-form>
    </el-card>

    <el-card style="margin-top: 20px">
      <div class="card-header">
        <TableTittle :tittle="TableName[0]"></TableTittle>
      </div>
      <el-table :data="tableData"
                :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
                :cell-style="{ textAlign: 'center', fontSize: '13px' }"
                border
                @selection-change="handleSelectionChange">
        <el-table-column type="selection"
                         width="50"
                         fixed="left">
        </el-table-column>
        <el-table-column prop="id"
                         label="ID"
                         width="80"
                         fixed="left"></el-table-column>
        <el-table-column prop="student_realname"
                         label="学员姓名"
                         width="130"
                         show-overflow-tooltip></el-table-column>
        <el-table-column prop="student_phone"
                         label="手机号"
                         width="130"
                         show-overflow-tooltip></el-table-column>
        <el-table-column prop="product_names"
                         label="报名班次"
                         width="200"
                         show-overflow-tooltip></el-table-column>
        <el-table-column prop="exam_paper_name"
                         label="试卷名称"
                         width="200"
                         show-overflow-tooltip></el-table-column>
        <el-table-column prop="name"
                         label="考试状态"
                         show-overflow-tooltip
                         width="80">
          <template slot-scope="scope">
            <el-tag type="danger"
                    size="mini"
                    v-if="scope.row.exam_status==0">未开始</el-tag>
            <el-tag size="mini"
                    v-if="scope.row.exam_status==1">已开考</el-tag>
            <el-tag type="success"
                    size="mini"
                    v-if="scope.row.exam_status==2">已交卷</el-tag>
            <el-tag type="success"
                    size="mini"
                    v-if="scope.row.exam_status==3">超时卷</el-tag>
            <el-tag type="danger"
                    size="mini"
                    v-if="scope.row.exam_status==4">未交卷</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="name"
                         label="批改状态"
                         show-overflow-tooltip
                         width="80">
          <template slot-scope="scope">
            <el-tag type="info"
                    size="mini"
                    v-if="scope.row.correct_status==0">未批改</el-tag>
            <el-tag type="warning"
                    size="mini"
                    v-if="scope.row.correct_status==1">待审核</el-tag>
            <el-tag type="success"
                    size="mini"
                    v-if="scope.row.correct_status==2">已批改</el-tag>
            <el-tag type="danger"
                    size="mini"
                    v-if="scope.row.correct_status==3">已拒绝</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="考试开始时间"
                         width="160"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{scope.row.start_exam_time?$moment(scope.row.start_exam_time).format("YYYY-MM-DD HH:mm"):""}}
          </template>
        </el-table-column>
        <el-table-column label="考试结束时间"
                         width="160"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{scope.row.end_exam_time?$moment(scope.row.end_exam_time).format("YYYY-MM-DD HH:mm"):""}}
          </template>
        </el-table-column>
        <!--     <el-table-column
          label="查看状态"
          show-overflow-tooltip
          width="80"
        >
          <template slot-scope="scope">
            <el-tag
              type="warning"
              size="mini"
              v-if="scope.row.read_status==0"
            >未查看</el-tag>
            <el-tag
              type="success"
              size="mini"
              v-if="scope.row.read_status==1"
            >已查看</el-tag>
          </template>
        </el-table-column> -->
        <el-table-column label="发放人"
                         width="130"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{scope.row.creator ? scope.row.creator.alias : ''}}
          </template>
        </el-table-column>
        <el-table-column prop="corrector_realname"
                         label="阅卷人"
                         width="130"
                         show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="发放时间"
                         width="160"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{scope.row.create_time?$moment(scope.row.create_time).format("YYYY-MM-DD HH:mm"):""}}
          </template>
        </el-table-column>
        <el-table-column label="批改时间"
                         width="160"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{scope.row.correct_time?$moment(scope.row.correct_time).format("YYYY-MM-DD HH:mm"):""}}
          </template>
        </el-table-column>
        <el-table-column fixed="right"
                         label="操作"
                         width="200">
          <template slot-scope="scope">
            <el-link type="primary"
                     :underline="false"
                     @click="handleToDetail(scope.row)">查看</el-link>
            <el-link type="primary"
                     :underline="false"
                     @click="handleToEdit(scope.row)">编辑</el-link>
            <el-link type="danger"
                     :underline="false"
                     @click="handleToRemove(scope.row.id)">删除</el-link>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 16px">
        <el-button size="mini"
                   type="danger"
                   @click="handleToDeleteAll()">批量删除</el-button>
        <div class="block">
          <el-pagination background
                         layout="total, prev, pager, next, sizes, jumper"
                         :current-page="Number(query.pageIndex)"
                         :page-sizes="[10, 20, 50, 100, 500]"
                         :page-size="Number(query.pageSize)"
                         :total="Number(pageTotal)"
                         @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"></el-pagination>
        </div>
      </div>
    </el-card>
    <AddExamProcess :isVisible="createDialog"
                    @close="createDialog=false"
                    @submit="getList()" />
    <EditExamProcess :id="editId"
                     :isVisible="editDialog"
                     @close="editDialog=false"
                     @submit="getList()" />
  </div>
</template>

<script>
import TableTittle from '../../../components/TableTittle/TableTittle.vue'
import AddExamProcess from '@/components/DialogComponents/PracticeTest/AddExamProcess.vue'
import EditExamProcess from '@/components/DialogComponents/PracticeTest/EditExamProcess.vue'

import {
  mockexamprocesslist,
  mockexamprocessDelete,
  mockexamprocesslistDelete,
} from '@/request/api'
export default {
  components: {
    TableTittle,
    AddExamProcess,
    EditExamProcess,
  },
  data() {
    return {
      TableName: ['考试列表'],
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
        cid: localStorage.getItem('cid'),
      },
      pageTotal: 0,
      tableData: [],
      createDialog: false,
      editId: null,
      editDialog: false,
      detailId: null,
      chooseList: [],
      detailDialog: false,
      filters: {
        read_status: [
          { label: '未查看', value: '0' },
          { label: '已查看', value: '1' },
        ],
        correct_status: [
          { label: '未批改', value: '0' },
          { label: '待审核', value: '1' },
          { label: '已批改', value: '2' },
          { label: '已拒绝', value: '3' },
        ],
        exam_status: [
          { label: '未开考', value: '0' },
          { label: '已开考', value: '1' },
          { label: '已交卷', value: '2' },
          { label: '超时卷', value: '3' },
          { label: '未交卷', value: '4' },
        ],
        student_status: [
          { label: '内部', value: 1 },
          { label: '外部', value: 0 },
        ],
      },
    }
  },
  filters: {},
  watch: {},
  created() {
    this.parseQuery()

    this.getList()
    this.$store.dispatch('getStaffList')
    this.$store.dispatch('getProducts')
  },
  mounted() {},
  methods: {
    handleSizeChange(val) {
      this.query.pageSize = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val
      this.getList()
    },

    getList(isReset = false) {
      if (isReset) {
        this.$set(this.query, 'pageIndex', 1)
      }
      const params = Object.assign({}, this.query)
      if (params.product_ids) {
        params.product_ids = params.product_ids.join(',')
      }
      if (params.creator) {
        params.creator = params.creator.join(',')
      }
      if (params.read_status) {
        params.read_status = params.read_status.join(',')
      }
      if (params.exam_status) {
        params.exam_status = params.exam_status.join(',')
      }
      if (params.correct_status) {
        params.correct_status = params.correct_status.join(',')
      }
      mockexamprocesslist(params).then((res) => {
        this.tableData = res.data.list || []
        this.pageTotal = res.data.count
      })
      this.syncQuery()
    },
    // 解析URL请求参数
    parseQuery() {
      Object.keys(this.$route.query).forEach((key) => {
        this.query[key] = this.$route.query[key]
      })
      if (this.query.product_ids) {
        this.query.product_ids = this.query.product_ids.split(',')
      }
      if (this.query.creator) {
        this.query.creator = this.query.creator.split(',')
      }
      if (this.query.read_status) {
        this.query.read_status = this.query.read_status.split(',')
      }
      if (this.query.exam_status) {
        this.query.exam_status = this.query.exam_status.split(',')
      }
      if (this.query.correct_status) {
        this.query.correct_status = this.query.correct_status.split(',')
      }
    },
    // 同步URL请求参数
    syncQuery() {
      const params = Object.assign({}, this.query)
      let isSync = Object.keys(params).some(
        (key) => this.$route.query[key] != params[key]
      )
      if (params.product_ids) {
        params.product_ids = params.product_ids.filter((v) => v).join(',')
      }
      if (params.creator) {
        params.creator = params.creator.filter((v) => v).join(',')
      }
      if (params.read_status) {
        params.read_status = params.read_status.filter((v) => v).join(',')
      }
      if (params.exam_status) {
        params.exam_status = params.exam_status.filter((v) => v).join(',')
      }
      if (params.correct_status) {
        params.correct_status = params.correct_status.filter((v) => v).join(',')
      }

      isSync &&
        this.$router.replace({
          query: Object.assign({}, this.$route.query, params),
        })
    },
    handleToEdit(item) {
      this.editId = item.id
      this.editDialog = true
    },
    handleToDetail(item) {
      this.$router.push(`/practicetest/testdetail/detail/${item.id}`)
    },
    handleToDeleteAll() {
      if (this.chooseList.length == 0) {
        this.$message.error('还没有选择要删除的数据')
        return
      }
      this.$confirm('确定要删除这些信息吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        let ids = this.chooseList.map((v) => {
          return v.id
        })
        mockexamprocesslistDelete({ ids }).then((res) => {
          this.getList(this.query)
        })
      })
    },
    handleSelectionChange(val) {
      this.chooseList = val
    },
    handleToRemove(id) {
      this.$confirm('确定要删除此条信息吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        mockexamprocessDelete({ id }).then((res) => {
          this.$nextTick(() => {
            this.getList(this.query)
          })
        })
      })
    },
  },
}
</script>

<style lang="less" scoped>
</style>