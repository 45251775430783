<template>
  <div>
    <el-dialog title="编辑试卷"
               :visible.sync="dialogFormVisible"
               width="700px"
               @close="emitClose">
      <el-form :model="dataForm"
               label-position="right"
               ref="formRules"
               :rules="rules"
               label-width="100px"
               size="small">
        <el-form-item label="试卷名称："
                      prop="name">
          <el-input v-model.trim="dataForm.name"
                    placeholder="请输入试卷名称"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="院校名称："
                      prop="college">
          <el-select v-model="dataForm.college"
                     placeholder="请输入选择院校名称"
                     filterable
                     clearable
                     @change="collegeChange">
            <el-option v-for="(item,index) in $store.state.collegeList"
                       :key="index"
                       :label="item.name"
                       :value="{ value: item.code, label: item.name}">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="专业名称："
                      prop="major">
          <el-select v-model="dataForm.major"
                     placeholder="请选择专业名称"
                     clearable
                     filterable>
            <el-option v-for="(item,index) in majorOptions"
                       :key="index"
                       :label="item.name"
                       :value="{ value: item.code, label: item.name}">
              <span>{{ `（${item.code}）${item.name}` }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="阅卷人："
                      prop="corrector_">
          <el-select v-model="dataForm.corrector_"
                     placeholder="请输入手机号搜索学长信息"
                     clearable
                     filterable
                     remote
                     :remote-method="remoteMethod"
                     :loading="loading">
            <el-option v-for="(item,index) in seniorList"
                       :key="index"
                       :label="item.realname"
                       :value="item.id">
              <span>{{ `${item.realname}/${item.mobile}` }}</span>
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="emitClose">取 消</el-button>
        <el-button type="primary"
                   @click="submit('formRules')">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import { majorlist, mockexampaperOne, mockexampaperEdit } from '@/request/api'
import { adminUrl, crmUrl, manageUrl } from '@/utils/helper.js'
import axios from 'axios'
export default {
  components: {},
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: () => {
        return null
      },
    },
  },
  data() {
    return {
      dataForm: {},
      dialogFormVisible: this.isVisible,
      majorOptions: [],
      rules: {
        name: [{ required: true, message: '请输入模考名称', trigger: 'blur' }],
        year: [{ required: true, message: '请选择年份', trigger: 'change' }],
        author: [
          { required: true, message: '请输入选择出题人', trigger: 'change' },
        ],
        college: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!this.dataForm.college_code || !this.dataForm.college_name) {
                callback(new Error('请选择院校'))
              } else {
                callback()
              }
            },
          },
        ],
        major: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!this.dataForm.major_code || !this.dataForm.major_name) {
                callback(new Error('请选择专业'))
              } else {
                callback()
              }
            },
          },
        ],
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now()
        },
      },
      seniorList: [],
      loading: false,
    }
  },
  mounted() {},
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val
      if (val) {
        this.$store.dispatch('getAllColleges')
        this.$store.dispatch('fetchAdminSeniorList')
        this.fetchDetail()
      }
    },
  },
  methods: {
    fetchDetail() {
      console.log('jkjkjkj')
      mockexampaperOne({ id: this.id }).then((res) => {
        this.dataForm = res.data
        this.$set(this.dataForm, 'college', {
          value: res.data.college_code,
          label: res.data.college_name,
        })
        this.getMajor(res.data.college_code)
        this.$set(this.dataForm, 'major', {
          value: res.data.major_code,
          label: res.data.major_name,
        })
        if (res.data.corrector) {
          this.$set(this.dataForm, 'corrector_', res.data.corrector.id)
          this.seniorList[0] = res.data.corrector
          console.log('this.seniorList', this.seniorList)
        }
      })
    },
    emitClose() {
      this.students = []
      this.staffs = []
      this.dataForm = {}
      this.$emit('close')
    },
    collegeChange(e) {
      this.majorOptions = []
      if (e) {
        this.getMajor(e.value)
      }
    },
    getMajor(college_code) {
      let query = {
        isPaging: 0,
        college_code: college_code,
      }
      majorlist(query).then((res) => {
        this.majorOptions = res.data.list || []
      })
    },
    submit(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          let query = {
            id: this.id,
            cid: localStorage.getItem('cid'),
            name: this.dataForm.name,
            year: this.dataForm.year,
            mock_exam_id: this.dataForm.mock_exam_id,
            college_code: this.dataForm.college.value,
            college_name: this.dataForm.college.label,
            major_code: this.dataForm.major.value,
            major_name: this.dataForm.major.label,
            corrector: this.dataForm.corrector_,
          }
          mockexampaperEdit(query).then((res) => {
            this.$message.success('提交成功')
            this.$emit('submit')
            this.emitClose()
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    fetchList(query) {
      // admin学长列表请求
      // fetchAdminSeniorList({ state }, query = {}) {
      let str = crmUrl()
      let data = {
        isPaging: 0,
        pageIndex: 1,
        pageSize: 9999,
        keyword: query,
      }
      axios({
        url: `${str}/api/v1/back/senior/list`,
        method: 'get', //可以省略 不写默认是get
        params: data,
        header: {
          Authorization: localStorage.getItem('token'),
        },
      }).then((res) => {
        this.seniorList = res.data.list
      })
      // }
    },

    remoteMethod(query) {
      this.fetchList(query)
      // this.$store.dispatch('fetchAdminSeniorList', { keyword: query })
      // let reg = /^(-|\+)?\d+$/
      // if (reg.test(query)) {
      //   this.$store.dispatch('fetchAdminSeniorList', { mobile: query })
      //   setTimeout(() => {
      //     this.seniorList = this.$store.state.seniorList
      //   }, 500);
      // } else {
      //   this.$message.error('请输入完整的手机号')
      // }
    },
  },
}
</script>

<style lang="less" scoped>
.el-input,
.el-select {
  width: 100%;
}
</style>